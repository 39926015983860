<!--This component contains the styling and slots for the deck embeds market and low pricing-->
<template>
  <div class="deck-price">
    <span class="group" :class="textAlignment">
      <tool-tip :message="toolTipMessage" icon id="deck-market-price-tool-tip" />
      <div class="price">
        <span class="market">Market Price:</span>
        <slot />
      </div>
    </span>
  </div>
</template>

<script>
import prices from '@/lib/prices';
import ToolTip from '@/components/elements/ToolTip.vue';
import deviceType from '@/mixins/deviceType';

export default {
  name: 'deck-embed-deck-price',
  components: {
    ToolTip,
  },
  mixins: [deviceType],
  props: {
    priceHigh: {
      type: Number,
      required: false,
      default: null,
    },
    textAlign: {
      type: String,
      default: 'left',
      required: false,
      validator(val) {
        return ['right', 'center', 'left'].includes(val)
      }
    }

  },
  data() {
    return {
      marketPrice: 'n/a',
      allowPrices: true,
      toolTipMessage: 'Market prices update frequently and may slightly differ from the price shown.',
    };
  },
  computed: {
    textAlignment() {
      return `${this.textAlign}-text`;
    }
  },
  created() {
    this.allowPrices = !prices.disallowPrices();
    this.formatPrice();
  },
  beforeUpdate() {
    this.formatPrice();
  },
  methods: {
    formatPrice() {
      if (this.$slots.default.length > 0) {
        this.$slots.default[0].text = prices.marketPrice(this.$slots.default[0].text);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .price {
    display: flex;
  }

  @include breakpoint(1024) {
    align-items: flex-end;
  }

  .group {
    display: flex;
    flex-direction: row-reverse;
    color: $martech-text-primary;
    font-weight: $martech-weight-semibold;
    letter-spacing: 0.4px;
    width: 100%;
    font-size: $martech-type-14;
    line-height: 20px;

    @include breakpoint(1024) {
      flex-direction: row;
    }

    &.center-text {
      justify-content: center;
    }

    &.left-text {
      justify-content: left;
    }

    &.right-text {
      justify-content: right;
    }

    @include breakpoint(1024) {
      font-size: $martech-type-16;
      line-height: 24px;
    }

    :deep(svg) {
      height: 15.5px;
      width: 15.5px;
      margin: 2px 0 0 $martech-spacer-1;

      @include breakpoint(1024) {
        margin: 4px $martech-spacer-1 0 0;
      }
    }

    :deep .martech-tool-tip {
      display: flex;
      align-items: center;
      padding-left: 0 0 0 $martech-spacer-1;

      @include breakpoint(1024) {
        padding: 0 $martech-spacer-1 0 0;
      }
    }
  }

  .market {
    color: $martech-text-primary;
    margin-right: $martech-spacer-2;
    font-weight: $martech-weight-normal;
  }
}
</style>