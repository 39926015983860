<template>
  <div ref="root" class="martech-image__wrapper" :data-testid="componentID()">
    <img
      v-if="visible && hasRoot"
      class="martech-image__item"
      :class="authorPhotoSizes"
      :src="imageURL(source || placeholderImage, { maxDimensions: '200x200', quality: 75, })"
      alt="">
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import useDelayLoad from '@/use/delayLoad';
import useImageAlter from '@/use/image/alter';
import useComponentId from '@/use/useComponentId';

const { imageURL, hasRoot } = useImageAlter();
const root = ref(null);
const { visible } = useDelayLoad(root);
const { componentID } = useComponentId();

const props = defineProps({
  source: {
    type: String,
    default: '',
    required: false,
  },
  size: {
    type: String,
    default: 'small',
    required: false,
  },
});

const placeholderImages = [
  'https://tcgplayer-cdn.tcgplayer.com/infinite/images/Content-Site-Placeholder-Images_Author_Dragon.svg',
  'https://tcgplayer-cdn.tcgplayer.com/infinite/images/Content-Site-Placeholder-Images_Author_Kraken.svg',
  'https://tcgplayer-cdn.tcgplayer.com/infinite/images/Content-Site-Placeholder-Images_Author_Phoenix.svg',
];

const authorPhotoSizes = computed(() => {
  switch (props.size) {
    case 'xx-large':
      return 'xx-large';
    case 'x-large':
      return 'x-large';
    case 'large':
      return 'large';
    case 'medium':
      return 'medium';
    case 'sm-md':
      return 'sm-md';
    case 'x-small':
      return 'x-small';
    default:
      return 'small';
  }
});

const placeholderImage = placeholderImages[Math.floor(Math.random() * placeholderImages.length)];
</script>

<style lang="scss" scoped>
.martech-image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item {
    width: 100%;
    transition: all 0.15s ease-in-out;
    object-fit: cover;
    object-position: top center;
    border-radius: 100%;
    &.x-small {
      width: 18px;
      height: 18px;
    }
    &.small {
      width: 28px;
      height: 28px;
    }
    &.sm-md {
      width: 48px;
      height: 48px;
    }
    &.medium {
      width: 65px;
      height: 65px;
    }
    &.large {
      width: 88px;
      height: 88px;
    }
    &.x-large {
      width: 120px;
      height: 120px;
    }

    &.xx-large {
      width: 149px;
      height: 149px;
    }
  }
}
</style>
